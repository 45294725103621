<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">입찰 관리</h2>
      </div>
      <div class="body_section">
        <BidListFilterBar
          ref="filterBar"
          :keywordDatas="KEYWORD_DATAS"
          :defaultKeywordSelectedId="defaultKeywordSelectedId"
          :defaultKeywordInputText="defaultKeywordInputText"
          :statusList="statusList"
          :defaultStateId="defaultStateId"
          :defaultDates="defaultDates"
          @onClickSearch="onClickSearch"
          @onClickExcelBtn="onClickExcelBtn"
        />

        <TableHead>
          <template v-slot:headRight>
            <SelectBoxPageRange
              :currentMaxRowCount.sync="size"
              @update:currentMaxRowCount="onChangeRowCount"
            />
          </template>
        </TableHead>
        <ListPage
          :list="dataList"
          :currentPageIndex="currentPageIndex"
          :totalPageCount="totalPageCount"
          :maxRowCount="size"
          :maxPaginationCount="maxPaginationCount"
          @onChangePage="onChangePage"
        >
          <template v-slot:colgroup>
            <col style="width: 108px" />
            <!-- 진행 상태 -->
            <col style="width: 89px" />
            <!-- 등록일 -->
            <col style="width: 152px" />
            <!-- 입찰 공고번호 -->
            <col style="width: 214px" />
            <!-- 입찰 공고명 -->
            <col style="width: 140px" />
            <!-- 구매 품목 -->
            <col style="width: 89px" />
            <!-- 입찰 시작일 -->
            <col style="width: 89px" />
            <!-- 입찰 마감일 -->
            <col style="width: 110px" />
            <!-- 입찰 참여 현황 -->
            <col style="width: 157px" />
            <!-- 구매 담당자 -->
            <col style="width: 92px" />
            <!-- 마감 -->
          </template>
          <template v-slot:tr>
            <th>진행 상태</th>
            <th>공고 등록일</th>
            <th>입찰 공고번호</th>
            <th>입찰 공고명</th>
            <th>구매 품목</th>
            <th>입찰 시작일</th>
            <th>입찰 마감일</th>
            <th>입찰 참여 현황</th>
            <th>구매 담당자</th>
            <th>마감</th>
          </template>
          <template v-slot:row="slotProps">
            <BidListLine
              :rowData="slotProps.rowData"
              :isCloseAnnounce="isCloseAnnounce"
              @onClickisPopCloseReason="onClickisPopCloseReason"
            />
          </template>
          <template v-slot:emptyList>
            <tr>
              <td colspan="10" class="td_empty">입찰 업체가 없습니다</td>
            </tr>
          </template>
        </ListPage>
      </div>
    </div>
    <template v-slot:popup>
      <CloseAnnouncePopup
        v-if="isCloseReasonPop"
        :loginIdWithName="myLoginIdWithName"
        @onClickClose="onClickClose"
        @onClickSubmit="onClickAnnounceClose"
      />
      <AlertPopup
        v-if="isPopAlert"
        alertText="기간을 설정하지 않으셨습니다.<br>모든 데이터를 다운로드 하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCancelAlert"
        @onClickPrimary="onClickConfirmAlert"
      />
      <ExcelDonwloadPopup
        v-if="isPopExcel"
        :excelDataList="excelHeaderList"
        :checkedNames.sync="checkedNamesExcel"
        @onClickClose="onClickCloseExcel"
        @onClickDownload="onClickDownloadExcel"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import BidListFilterBar from "@/components/shared/bid/list/BidListFilterBar";
import TableHead from "@/components/shared/TableHead";
import ListPage from "@/components/shared/ListPage";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";
import BidListLine from "@/components/admin/bid/list/BidListLine";
import AlertPopup from "@/components/layout/popup/AlertPopup";
import ExcelDonwloadPopup from "@/components/layout/popup/ExcelDonwloadPopup";
import CloseAnnouncePopup from "@/components/admin/bid/popup/CloseAnnouncePopup";

import ApiService from "@/services/ApiService";
import LocalStorageManager from "@/LocalStorageManager";

import excelKey from "@/constants/excelKey";

import BidListMixin from "@/mixins/bid/list/BidListMixin";

export default {
  components: {
    PageWithLayout,
    BidListFilterBar,
    TableHead,
    SelectBoxPageRange,
    BidListLine,
    AlertPopup,
    ExcelDonwloadPopup,
    CloseAnnouncePopup,
    ListPage,
  },
  mixins: [BidListMixin],
  data() {
    return {
      selectedItem: null,

      isCloseReasonPop: false,
      isCloseAnnounce: false,

      excelKey: excelKey.BID,
    };
  },
  computed: {
    myLoginIdWithName() {
      return LocalStorageManager.shared.getUserLoginIdWithName();
    },
  },
  methods: {
    onClickisPopCloseReason(rowData) {
      this.selectedItem = rowData;

      this.isCloseReasonPop = true;
    },
    onClickAnnounceClose(text) {
      const postCid = this.selectedItem.postCid;

      this.closeAnnounce(postCid, text);
    },
    async closeAnnounce(postCid, text) {
      const path = `${this.$apiPath.BID}/${postCid}${this.$apiPath.CLOSE}`;
      const userData = LocalStorageManager.shared.getUserData();
      const companyName = userData.companyName;

      const obj = {
        reason: text,
        name: companyName,
      };

      const result = await ApiService.shared.putData(path, obj);
      if (result.code !== "200") {
        this.alert(result.text);
        return;
      }

      // this.refresh();
      // this.refreshSamePage();
      const params = this.checkUrlQuery();
      this.getDataInit(params);

      this.isCloseReasonPop = false;
    },
    onClickClose() {
      this.isCloseReasonPop = false;
    },
  },
};
</script>
