import { mapState } from "vuex";
import { BID_LIST_ACTION } from "@/store/modules/bid/action";

import PageMixin from "@/mixins/PageMixin";
import ListPageMixin from "@/mixins/ListPageMixin";

const BidListMixin = {
  mixins: [PageMixin, ListPageMixin],
  data() {
    return {
      API_PATH: this.$apiPath.BID,

      KEYWORD_DATAS: [
        { id: "requestCid", code: "requestCid", desc: "구매요청번호" },
        { id: "postCid", code: "postCid", desc: "입찰 공고번호" },
        { id: "title", code: "title", desc: "입찰 공고명" },
        { id: "name", code: "name", desc: "구매 담당자" },
      ],

      defaultKeywordSelectedId: "requestCid",
    };
  },
  beforeMount() {
    const params = this.checkUrlQuery();

    this.$store.dispatch(BID_LIST_ACTION).then(() => {
      this.getDataInit(params);
    });
  },
  computed: {
    ...mapState({
      statusList: (state) => state.bid.statusList,
    }),
  },
  methods: {
    // 주소 기준으로 현재 페이지에 필요한 params 가져온다.
    // 주소 기준으로 필터바 초기값을 셋팅한다.
    checkUrlQuery() {
      const { query } = this.$route;

      const objPaging = this.checkQueryPaging(query);

      const objKeyword = this.checkQueryKeywords(query, this.KEYWORD_DATAS);

      const objDates = this.checkQueryDates(query, "startDate", "endDate");

      const objStatus = this.checkQueryStatus(query);

      const objCateNum = this.checkQueryCateNum(query);

      return Object.assign({}, objPaging, objKeyword, objDates, objStatus, objCateNum);
    },
    getFilterbarParams(objData) {
      const {
        keywordSelectedId,
        keywordInputText,
        startDt,
        endDt,
        status,
        category0,
        category1,
        category2,
      } = objData;

      let params = {};

      if (keywordSelectedId && keywordInputText) {
        params[keywordSelectedId] = keywordInputText;
      }

      if (startDt && endDt) {
        params["startDate"] = startDt;
        params["endDate"] = endDt;
      }

      if (status) {
        params.status = status;
      }

      const cateNum = category2 || category1 || category0;
      if (cateNum) {
        params.cateNum = cateNum;
      }

      return params;
    },
  },
};
export default BidListMixin;
