<template>
  <ListRow>
    <td>
      <span :class="['badge_state', stateType]">{{ rowData.tenderStatusName }}</span>
    </td>
    <td>{{ regDate }}</td>
    <td>{{ rowData.postCid }}</td>
    <td class="td_ellip" :data-ellip="rowData.title">
      <router-link
        :to="toPath"
        class="link_subject"
        :data-ellip="rowData.title"
        v-html="rowData.title"
      />
      <!-- <a
          :class="['link_subject']"
          @click="onClickDetailLink(toPath)"
          :data-ellip="rowData.title"
          v-html="rowData.title"
      ></a> -->
    </td>
    <td class="td_ellip align_left" :data-ellip="rowData.itemName">
      {{ rowData.itemName }}
    </td>
    <td>{{ startDate }}</td>
    <td>{{ endDate }}</td>
    <td>{{ rowData.tenderCount }}</td>
    <td>{{ managerName }}</td>
    <td>
      <span v-if="isAnnounceClosed">마감</span>
      <button v-else type="button" class="btn_fourthly btn_small" @click="onClickisPopCloseReason">
        마감
      </button>
    </td>
  </ListRow>
</template>

<script>
import ListRow from "@/components/shared/ListRow";
import ListLineMixin from "@/mixins/ListLineMixin";
import { makePathWithQuery } from "@/utils/urlUtils";

export default {
  name: "BidListLine",
  components: {
    ListRow,
  },
  mixins: [ListLineMixin],
  computed: {
    toPath() {
      const postCid = this.rowData.postCid;
      const path = makePathWithQuery(this.$routerPath.BID_VIEW, postCid, this.$route.query);
      return path;
    },
    isAnnounceClosed() {
      return this.rowData.status === "C";
    },
    stateType() {
      switch (this.rowData.tenderStatusName) {
        case "개봉 완료":
          return "on";
        case "개봉 대기":
          return "";
        case "선정 유찰":
        case "선정 완료":
          return "disabled";
      }
      return "";
    },
  },
  methods: {
    onClickisPopCloseReason() {
      this.$emit("onClickisPopCloseReason", this.rowData);
    },
  },
};
</script>
