<template>
  <FilterBarBase
    :excelBtn="false"
    @onClickSearch="onClickSearch"
    @onClickExcelBtn="onClickExcelBtn"
    @onClickReset="onClickReset"
  >
    <dl class="w152">
      <dt>진행 상태</dt>
      <dd>
        <SelectBox
          class="w152"
          :dataList="statusList"
          :isDisabled="isDisabledStatus"
          :value.sync="stateId"
        />
      </dd>
    </dl>
    <dl>
      <dt>입찰 기간</dt>
      <dd>
        <DateRangeQuickSelect :dates.sync="dates" />
      </dd>
      <!-- <dt>분류</dt>
      <dd>
        <CategoryGroupIncludeData
          :category0.sync="category0"
          :category1.sync="category1"
          :category2.sync="category2"
        />
      </dd> -->
      <dt>검색어</dt>
      <dd>
        <div class="group_form">
          <SelectBox class="w208" :dataList="keywordDatas" :value.sync="keywordSelectedId" />
          <!-- <Radio
            v-else
            name="filterSearch"
            :dataList="keywordDatas"
            :selectedId.sync="keywordSelectedId"
          /> -->
          <Input
            :class="[inputWidthClassName]"
            :value.sync="keywordInputText"
            @onEnterKey="onClickSearch"
          />
        </div>
      </dd>
    </dl>
  </FilterBarBase>
</template>

<script>
import FilterBarBase from "@/components/shared/filterBar/FilterBarBase";
import Input from "@/components/common/input/Input";
import Radio from "@/components/common/radio/Radio";
import SelectBox from "@/components/common/selectBox/SelectBox";
import CategoryGroupIncludeData from "@/components/common/selectBox/CategoryGroupIncludeData";
import DateRangeQuickSelect from "@/components/common/calendar/DateRangeQuickSelect";

import LocalStorageManager from "@/LocalStorageManager";

import FilterBarMixin from "@/mixins/FilterBarMixin";

export default {
  components: {
    FilterBarBase,
    Input,
    Radio,
    SelectBox,
    DateRangeQuickSelect,
    CategoryGroupIncludeData,
  },
  mixins: [FilterBarMixin],
  computed: {
    isAdmin() {
      const ret = LocalStorageManager.shared.getIsAdmin();

      return ret;
    },
    inputWidthClassName() {
      // if (this.keywordDatas.length > 3) return 'w425';
      // if (this.keywordDatas.length === 3) return 'w307';
      return "w399";
    },
  },
  methods: {
    onClickSearch(e) {
      let obj = this.getCommonFilterbarData();

      this.$emit("onClickSearch", obj);
    },
    onClickExcelBtn() {
      if (this.isAdmin) {
        const isDatesSelected = this.dates && this.dates.length === 2;

        this.$emit("onClickExcelBtn", isDatesSelected);
      }
    },
    onClickReset() {
      this.onClickResetFilterBar();
      this.stateId = "0";
    },
  },
};
</script>
